<div class="header flex">
    <h1 qa="h11">Create call report</h1>
    @if (compact) {
        <app-input-mode-toggle [compact]="true" />
    }
</div>

<div class="message flex">
    <app-ghostwriter-avatar/>
    
    <div class="text" qa="headtext">
        <h2 qa="h2">Hi, I’m Paige, your AI Ghostwriter.</h2>
        
        I’ll ask you a few questions about your meeting, so please have your notes ready. 
        I’ll combine your answers with SalesPrep insights and draft the following for you:
        
        <ul>
            <li>A <strong>Call Report</strong> to share inside your company</li> 
            <li>A <strong>Customer Recap</strong> you can email to your customer contact(s)</li>
        </ul>
        
        <h2>How would you like me to interview you?</h2>

        <app-input-mode-toggle />
    </div>
</div>
