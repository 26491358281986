import {Injectable, signal} from '@angular/core';
import {Roles} from "../model";
import {MessageService} from "./message.service";

export interface IVoiceSelection {
    role: Roles;
    voice: string;
}
@Injectable({
    providedIn: 'root'
})
export class SpeechSynthesisService {

    enabled = signal(false);
    speaking = signal(false);
    selectedVoices = signal<IVoiceSelection[]>([]);
    supported = signal(true);
    
    private _voices: SpeechSynthesisVoice[] | undefined;
    
    constructor(private messageService?: MessageService) {        
        this.enabled.set(!!speechSynthesis && localStorage.getItem('text-to-speech-enabled') == "1");
    }
    
    getVoices() {
        if (!this._voices) {
            this._voices = speechSynthesis.getVoices();
            console.log('[SpeechSynthesisService] voices', this._voices);
            this._loadVoices();
        }
        return this._voices;
    }
    
    private _loadVoices() {
        this.selectedVoices.set([
            { role: Roles.Coach, voice: this._getVoice(Roles.Coach)?.voiceURI || '' },
            { role: Roles.Customer, voice: this._getVoice(Roles.Customer)?.voiceURI || '' },
            { role: Roles.User, voice: this._getVoice(Roles.User)?.voiceURI || '' }
        ]);
    }
    
    getVoiceUri(role: Roles): string[] {
        const v = localStorage.getItem('voice-' + role);
        if (v) {
            return [v];
        }
        
        switch (role) {
            case Roles.Coach:
                return [
                    //Firefox
                    'urn:moz-tts:sapi:Microsoft Zira Desktop - English (United States)?en-US', //  Windows                    
                    'urn:moz-tts:osx:com.apple.voice.compact.en-US.Samantha', // Mac            
                    // Edge
                    'Microsoft Emma Online (Natural) - English (United States)', // big language set
                    'Microsoft Aria Online (Natural) - English (United States)', // smaller languages set
                    // Safari
                    'com.apple.voice.compact.en-US.Samantha',
                    // Chrome
                    'Google US English',
                    // Vivaldi
                    'Microsoft Zira - English (United States)'                    
                ];
            case Roles.Customer:
                return [
                    //Firefox
                    'urn:moz-tts:sapi:Microsoft David Desktop - English (United States)?en-US', //  Windows      
                    'urn:moz-tts:osx:com.apple.voice.compact.en-GB.Daniel', // Mac              
                    // Edge
                    'Microsoft Brian Online (Natural) - English (United States)',
                    'Microsoft Andrew Online (Natural) - English (United States)', // big language set
                    'Microsoft Guy Online (Natural) - English (United States)', // smaller languages set
                    // Safari
                    'com.apple.voice.compact.en-GB.Daniel',
                    // Chrome
                    'Google UK English Male',
                    // Vivaldi
                    'Microsoft Mark - English (United States)'                    
                ];
            default:
                return [
                    //Firefox
                    'urn:moz-tts:sapi:Microsoft Mark Desktop - English (United States)?en-US', //  Windows                    
                    'urn:moz-tts:sapi:Microsoft Zira Desktop - English (United States)?en-US', //  Windows      
                    'urn:moz-tts:osx:com.apple.voice.compact.en-ZA.Tessa', // Mac              
                    // Edge
                    'Microsoft Ava Online (Natural) - English (United States)', // big language set
                    'Microsoft Zira - English (United States)', // smaller languages set
                    // Safari
                    'com.apple.voice.compact.en-US.Samantha', // Use clarie on Mac as Claire and default for Novice
                    // Chrome
                    'Google UK English Female',
                    // Vivaldi
                    'Microsoft David - English (United States)',

                ];     
                //case Roles.AIGHOSTWRITER: for futuer feature
                    //Safari
                    //   'COM.APPLE.VOICE.SUPER-COMPACT.EN-AU.KAREN' 
        }        
    }
    
    private _getVoice(role: Roles) {
        const selectedVoices = this.getVoiceUri(role);
        const availableVoices = this.getVoices();
        for (const v of selectedVoices) {
            const voice = availableVoices.find(x => x.voiceURI == v);
            if (voice) {
                return voice;
            }            
        }        
        return undefined;
    }

    reset() {
        localStorage.removeItem('voice-' + Roles.Coach);
        localStorage.removeItem('voice-' + Roles.Customer);
        localStorage.removeItem('voice-' + Roles.User);
        
        this._loadVoices();
    }
    
    async selectVoice(e: IVoiceSelection) {
        console.log('[SpeechSynthesisService] selectVoice', e);
        
        localStorage.setItem('voice-' + e.role, e.voice);
        
        this.selectedVoices.update(x => 
            x.map(sel => sel.role == e.role ? e: sel));
    }

    stop() {
        if (speechSynthesis.speaking) {
            speechSynthesis.cancel();
        }
    }
    
    async speakVoice(text: string, voiceUri: string) {
        const v = this.getVoices().find(x => x.voiceURI == voiceUri);
        await this._speak(text, v);        
    }
    
    async speak(text: string, role:  Roles) {
        const voice = this._getVoice(role);
        return await this._speak(text, voice);
    }
    
    private _speak(text: string, voice:  SpeechSynthesisVoice | undefined) {
        console.log('[SpeechSynthesisService] speaking', text);
        this.speaking.set(true);
        this.supported.set(true);

        return new Promise<boolean>((resolve, reject) => {
            const utterance = new SpeechSynthesisUtterance(text);
            this.stop();
                        
            if (voice) {
                utterance.voice = voice;
            }
            
            utterance.onerror = (event) => {
                if (event.error != "interrupted" && event.error != "canceled") {
                    this._onNotSupported();
                    this.enabled.set(false);
                    console.error('[SpeechSynthesisService] speaking error', event.error);
                    resolve(false);
                } else {
                    resolve(true);
                }
            };
            
            utterance.onend = () => {
                console.log('[SpeechSynthesisService] speaking finished');
                this.speaking.set(false);
                resolve(true);
            };
            
            speechSynthesis.speak(utterance);
        });
    }

    private _onNotSupported() {
        if (this.supported()) {
            this.supported.set(false);
            this.messageService?.warning('Text-to-Speech functionality is currently working only in Edge, Chrome and Safari browsers');
        }
    }

    async enable() {
        this.enabled.set(true);
        this._save();
        return await this.speak('Chat messages will now be read aloud', Roles.Coach);
    }
    
    disable() {
        this.enabled.set(false);
        this.stop();
        this.enabled.set(false);
        this._save();
    }
    
    private _save() {
        localStorage.setItem('text-to-speech-enabled', this.enabled() ? "1" : "0");
    }
}
