<form #overviewForm="ngForm" (ngSubmit)="next(overviewForm)" qa="form">

    <h1 qa="h12">1. First, let’s make any needed updates to what was copied from your Pre-call Report.</h1>
    
    <div class="form">
    
        <label for="customer">Company I met with</label>
        <input type="text" id="customer" [(ngModel)]="data.customerCompany" required name="customer">

        <div class="flex">
            <div>
                <label for="customer-city">Customer's City</label>
                <input type="text" id="customer-city" [(ngModel)]="customerCity" required name="customer-city">
            </div>
            <div>
                <label for="customer-state">Customer's State or Country</label>
                <input type="text" id="customer-state" [(ngModel)]="customerState" required name="customer-state">
            </div>
        </div>

        <div class="flex">
            <div qa="meet-t">
                <label>Meeting Type</label>
                <app-dropdown [placeholder]="'- Please select -'" [items]="meetingTypes" [selectedItem]="selectedMeetingType" (selectedItemChange)="data.meetingType = $event.value" />
            </div>
            <div (click)="showDatePicker = true" qa="meet-d">
                <label for="meeting-date">Meeting Date</label>
                <div class="date-picker">
                    <input type="text" id="meeting-date" readonly [ngModel]="data.date | date: 'EEE, MMMM dd, yyyy'" required name="meeting-date">
                    <span (click)="showDatePicker = true;" class="icon material-icons">calendar_today</span>
                </div>
                
            </div>
        </div>

        <label for="job">Customer job-to-be-done</label>
        <input type="text" id="job" [(ngModel)]="data.job" required name="job">

        <label for="purpose">Purpose of my meeting</label>
        <textarea appAutosize id="purpose" [(ngModel)]="data.purpose" required name="purpose" rows="2"></textarea>

        <label for="scope">Scope of discussion</label>
        <textarea appAutosize id="scope" [(ngModel)]="data.scope" required name="scope" rows="2"></textarea>
    </div>
    
    <h1 qa="h13">1a. Colleagues that joined me…</h1>
    <div class="form" qa="colleagues">
        @for (x of data.interviewers; track x.name; let i = $index) {
            <div class="flex">
                <div>
                    @if (i == 0) {
                        <label [for]="'i-' + i" qa="l1">Name</label>
                    }
                    <input appAutofocus [autofocus]="!x.name" type="text" [id]="'i-' + i" [(ngModel)]="x.name" required [name]="'i-' + i">
                </div>
            </div>  
        }

        <button (click)="addInterviewer()" type="button" class="alternative" qa="add-colleague">
            <i class="material-icons">add</i>
            <span class="text">Add colleague</span>
        </button>
        
    </div>
    
    <h1>1b. I met with…</h1>
    <div class="form" qa="met">
        @for (x of data.interviewees; track x.name; let i = $index) {
            <div class="flex">
                <div>
                    @if (i == 0) {
                        <label [for]="'is-' + i" qa="l1">Name</label>
                    }
                    <input appAutofocus [autofocus]="!x.name && !x.title" type="text" [id]="'is-' + i" [(ngModel)]="x.name" required [name]="'is-' + i">
                </div>
                <div>
                    @if (i == 0) {
                        <label [for]="'isr-' + i" qa="l2">Role</label>
                    }
                    <input type="text" [id]="'isr-' + i" [(ngModel)]="x.title" required [name]="'isr-' + i">
                </div>
            </div>
        }

        <button (click)="addInterviewee()" type="button" class="alternative" qa="add-interviewee">
            <i class="material-icons">add</i>
            <span class="text" qa="add-interviewee">Add interviewee</span>
        </button>
    </div>
    
    <app-form-navigation-buttons [nextEnabled]="!!overviewForm.valid && data.meetingType > 0"  (onPrev)="prev()" />
        
</form>


@if (showDatePicker) {
    <app-info-panel [title]="'Select Date'" (onClose)="showDatePicker = false">
        <app-date-picker [(date)]="data.date" (dateChange)="showDatePicker = false" [allowPastDates]="true"/>
    </app-info-panel>
}