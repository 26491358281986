import {computed, Injectable, signal, WritableSignal} from '@angular/core';
import {ReportService} from "./report.service";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {CallReport, CallReportRequest, CallReportEditData, MeetingType, OfferingType, SalesPrepReport} from "../model";
import {catchError, finalize, map, of, retry, tap, throwError, timeout, timer} from "rxjs";
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";
import {MessageService} from "./message.service";
import {DatePipe} from "@angular/common";

const CALL_REPORT_KEY = 'call-report-';

@Injectable({
    providedIn: 'root'
})
export class CallReportBuilderService {

    generating = signal(false);
    generationFailed = signal(false);

    reportData = this.reportService.reportData;

    inputData = signal<CallReportEditData | undefined>(undefined);
    reportId = computed(() => this.reportData()?.id);
    
    constructor(private http: HttpClient, 
                private messageService: MessageService,
                private authService: AuthService, private reportService: ReportService) {        
    }
    
    private inputData$ = toObservable(this.reportService.reportData).pipe(
        map(data => this._prepareInputData(data)),
        tap(data => this.inputData.set(data))
    );

    _inputData = toSignal(this.inputData$, { initialValue: undefined });
    
    saveInputData(step: number | undefined =  undefined) {
        const d = this.inputData();
        if (d) {
            if (step) {
                d.step = step;
            }
            this.save(CALL_REPORT_KEY + d.reportId, d);
        }
    }

    generateReport() {
        const report = this.reportData();
        if (!report) throw Error('Report not loaded');
        
        const input = this.inputData();
        if (!input) throw Error('Input is empty');

        const topics = report.discussionTopics[0];
        const company = report.company;
        const tagRegex = /<\/?[^>]+(>|$)/g;        
        
        const data: CallReportRequest = {
            ...input,
            meetingDate: new DatePipe('en-US').transform(input.date, 'YYYY-MM-dd') ?? '',
            offering: report.offeringName,
            companyDescription: company.description,
            companyStrategy: company.strategy.map(({name, description}) => ({
                title: name,
                description
            })),
            companyNews: report.newsArticles.map(({title, summary}) => ({
                title: title.replace(tagRegex, ''),                
                description: summary.replace(tagRegex, ''),                
            })),
            
            marketTrends: topics.marketTrends.map(({title, description}) => ({title, description})),
            commonProblems: topics.commonProblems.map(({title, description}) => ({title, description})),
            processSteps: topics.processSteps.map(({title, description}) => ({title, description}))
        };

        this.generating.set(true);
        this.generationFailed.set(false);
        
        let retryNum = 0;
        let retryCount = 0;
        
        return this.http.post<CallReport>('/api/call-reports', data).pipe(
            map(response => {
                return {
                    ...response,
                    customerCompany: data.customerCompany,
                    meetingDate: data.meetingDate,
                    meetingType: data.meetingType,
                }
            }),
            timeout(120000),
            // retry({
            //     count: retryCount,
            //     delay: (error, count) => {
            //         console.warn(`[ReportBuilderService] Retrying stakeholders ${count} due to error:`, error);
            //         retryNum = count;
            //         return timer(2000); // Wait 2 seconds before retrying
            //     }
            // }),
            catchError(error => {
                console.error('[ReportBuilderService] Final error after retries:', error);
                if (retryNum < retryCount) {
                    return throwError(() => error); // Re-throw the error after retries or other handling
                } else {
                    this.messageService.error('Unable to generate call report');
                    this.generationFailed.set(true);
                    return of(undefined);
                }
            }),
            finalize(() => this.generating.set(false))
        );
    }
    
    
    loadInputData(id: string) {
        return this.http.get<CallReportEditData>('/api/call-reports/' + id).pipe(
            retry(1),
            tap(data => {
                data.reportId = id;
                const date = <string | undefined> (<any> data).meetingDate;
                if (date) {
                    data.date = new Date(date);
                }
                this.inputData.set(data);
                this.saveInputData(1);                
            })
        );        
    }
    
    private _prepareInputData(d: SalesPrepReport | undefined): CallReportEditData | undefined {
        const id = d?.id;
        if (!id) {
            return undefined;
        }

        const data = signal<CallReportEditData | undefined>(undefined);
        if (this.load(CALL_REPORT_KEY + id, data)) {
            return data();
        }
        
        const job = (d.offeringType == OfferingType.MaterialOrIngredientOrComponent ? 'produce ' : '') + d.job;
        const companyName = d.company.name;
        return {
            step: 1,
            reportId: id!,
            // author: this.authService.userName(),
            customerCompany: companyName,
            customerCompanyLocation: '',
            meetingType: MeetingType.Unknown,
            date: d.meetingDate ? new Date(d.meetingDate) : undefined,
            job: (d.offeringType == OfferingType.MaterialOrIngredientOrComponent ? 'produce ' : '') + d.job,
            purpose: '',
            scope: `We discussed how ${companyName} uses ${d.offeringName} to ${job}.`,
            interviewers: [],
            interviewees: d.roles.filter(r => !!r).map(r => ({ name: '', title: r})),
            outcomes: [],
            insights: '',
            opportunities: '',
            nextSteps: '',
            notes: '',
        }
    }

    private load<T>(key: string, obj: WritableSignal<T>) {
        const json = sessionStorage.getItem(key);
        if (json) {
            const x = <T>JSON.parse(json);
            obj.set(x);
            return true;
        }
        return false;
    }

    private save(key: string, obj: any) {
        console.log('[CallReportBuilderService] save', key, obj);
        if (obj) {
            const json = JSON.stringify(obj);
            sessionStorage.setItem(key, json);
        } else {
            sessionStorage.setItem(key, '');
        }
    }

    reset(id: string | undefined) {
        console.log('[CallReportBuilderService] Reset call report');
        // this.inputData.set(undefined);
        if (id) {
            // sessionStorage.removeItem(CALL_REPORT_KEY + id);
        }
    }

    generationComplete(report:CallReport) {
        const d = this.reportService.reportData();
        if (d) {
            d.callReport = report;
            const reports = this.reportService.reports;
            reports.update(list => list.map(x => x.id == d.id ? {
                ...x,
                callReport: report
            } : x));
        }
        
        this.reset(d?.id);        
    }
}
