<div class="header">
    <div class="sp-wrapped">
        <app-call-report-builder-header [compact]="true" qa="cl-build-head"/>
    </div>
</div>

<div class="sp-wrapped">
    @if (outcome) {
        
        <app-outcome-form
                [skipDisabled]="skipDisabled"
                [index]="index"
                [data]="outcome"
                [(skip)]="skip"
                (onPrev)="prev()"
                (onNext)="next($event)" 
                (onSkip)="skipStep()" qa="o-form"/>
    }
</div>
