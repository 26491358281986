import {Component, computed} from '@angular/core';
import {
    CallReportBuilderHeaderComponent
} from "../../components/call-report-builder-header/call-report-builder-header.component";
import {MeetingOverviewFormComponent} from "../../components/meeting-overview-form/meeting-overview-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CallReportBuilderService} from "../../../../services/call-report-builder.service";

@Component({
    selector: 'app-meeting-overview-screen',
    standalone: true,
    imports: [
        CallReportBuilderHeaderComponent,
        MeetingOverviewFormComponent
    ],
    templateUrl: './meeting-overview-screen.component.html',
    styleUrl: './meeting-overview-screen.component.scss'
})
export class MeetingOverviewScreenComponent {

    meetingOverviewData = this.reportBuilder.inputData;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private reportBuilder: CallReportBuilderService,                
                ) {
    }

    

    prev() {
        window.history.back();
    }
    
    next() {
        this.reportBuilder.saveInputData(2);
        this.router.navigate(['../outcomes', 1], { relativeTo: this.route });
    }
}
