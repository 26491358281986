<div class="header">
    <div class="sp-wrapped">
        <app-call-report-builder-header [compact]="true" qa="cl-build-head"/>
    </div>
</div>

<div class="sp-wrapped" qa="cl-text-div">
    @if (data()) {
        @switch (step) {
            @case (1) {
                <app-text-form [generatingReport]="generatingReport()" [step]="step" [(text)]="data()!.insights" (onPrev)="prev()" (onNext)="next()" />        
            }
            @case (2) {
                <app-text-form [generatingReport]="generatingReport()" [step]="step" [(text)]="data()!.opportunities" (onPrev)="prev()" (onNext)="next()" />
            }
            @case (3) {
                <app-text-form [generatingReport]="generatingReport()" [step]="step" [(text)]="data()!.nextSteps" (onPrev)="prev()" (onNext)="next()" />
            }
            @case (4) {
                <app-text-form [generatingReport]="generatingReport()" [step]="step" [(text)]="data()!.notes" (onPrev)="prev()" (onNext)="next()" />
            }
        }
    }
</div>
