import {Component, OnInit} from '@angular/core';
import {
    CallReportBuilderHeaderComponent
} from "../../components/call-report-builder-header/call-report-builder-header.component";
import {TextFormComponent} from "../../components/text-form/text-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CallReportBuilderService} from "../../../../services/call-report-builder.service";
import {SpinnerComponent} from "../../../../components/spinner/spinner.component";


@Component({
    selector: 'app-notes-screen',
    standalone: true,
    imports: [
        CallReportBuilderHeaderComponent,
        TextFormComponent,
    ],
    templateUrl: './notes-screen.component.html',
    styleUrl: './notes-screen.component.scss'
})
export class NotesScreenComponent implements OnInit {

    data = this.reportBuilder.inputData;
    generatingReport = this.reportBuilder.generating;

    step = 1;
    STEPS_COUNT = 4;

    constructor(private reportBuilder: CallReportBuilderService,
                private route: ActivatedRoute,
                private router: Router) {
    }
    
    ngOnInit() {
        this.route.params.subscribe(params => {
            const i = +this.route.snapshot.params['step'] || 1;

            if (this.step != i) {
                this.step = i;
            }
        })
    }

    prev() {
        if (this.step > 1) {
            this._updateUrl(this.step - 1);
        } else {
            const count = this.data()?.outcomes.length || 1;
            this.router.navigate(['../../outcomes', count], {relativeTo: this.route});
        }
    }

    next() {
        if (this.step < this.STEPS_COUNT) {
            this._updateUrl(this.step + 1);
        } else {            
            this.reportBuilder.generateReport()
                .subscribe(report => {
                    if (report) {
                        this.reportBuilder.generationComplete(report);
                        this.router.navigate(['/call-report', this.reportBuilder.reportId()]);
                    }
                });
        }
    }

    private _updateUrl(index: number) {
        this.reportBuilder.saveInputData();
        this.router.navigate(['..', index], {relativeTo: this.route});
    }
}
