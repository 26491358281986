import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input} from '@angular/core';
import {MessageService} from "../../../../services/message.service";

@Component({
    selector: 'app-input-mode-toggle',
    standalone: true,
    imports: [],
    templateUrl: './input-mode-toggle.component.html',
    styleUrl: './input-mode-toggle.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputModeToggleComponent {
    
    @HostBinding('class.compact')
    @Input() compact = false;

    @HostBinding('class.text-mode')
    @Input() textMode = true;
    
    
    constructor(private messageService: MessageService) {
    }

    notSupported() {
        this.messageService.info('Voice mode is not implemented yet');
    }
}
