import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {GhostwriterAvatarComponent} from "../../../../components/ghostwriter-avatar/ghostwriter-avatar.component";
import {InputModeToggleComponent} from "../input-mode-toggle/input-mode-toggle.component";

@Component({
    selector: 'app-call-report-builder-header',
    standalone: true,
    imports: [
        GhostwriterAvatarComponent,
        InputModeToggleComponent
    ],
    templateUrl: './call-report-builder-header.component.html',
    styleUrl: './call-report-builder-header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallReportBuilderHeaderComponent {
    @HostBinding('class.compact')
    @Input() compact = false;
}
