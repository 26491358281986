<div class="header" qa="hd">
    <div class="sp-wrapped">
        <app-call-report-builder-header/>
    </div>
</div>

<div class="sp-wrapped">
    @if (meetingOverviewData()) {
        <app-meeting-overview-form 
                [data]="meetingOverviewData()!"
                (onPrev)="prev()"
                (onNext)="next()"
        />
    }
</div>
