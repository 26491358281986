import {Component, OnInit} from '@angular/core';
import {
    CallReportBuilderHeaderComponent
} from "../../components/call-report-builder-header/call-report-builder-header.component";
import {OutcomeFormComponent} from "../../components/outcome-form/outcome-form.component";
import {CallReportRecodedOutcome} from "../../../../model";
import {ActivatedRoute, Router} from "@angular/router";
import {CallReportBuilderService} from "../../../../services/call-report-builder.service";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-outcomes-screen',
    standalone: true,
    imports: [
        CallReportBuilderHeaderComponent,
        OutcomeFormComponent
    ],
    templateUrl: './outcomes-screen.component.html',
    styleUrl: './outcomes-screen.component.scss'
})
export class OutcomesScreenComponent implements OnInit {

    skipDisabled = false;
    skip: boolean | undefined = undefined;
    index = 0;
    inputData = this.reportBuilder.inputData;

    private _outcome: CallReportRecodedOutcome | undefined;
    
    get outcome() {
        if (!this._outcome) {
            this._setOutcome();
        }
        return this._outcome;
    }

    constructor(private reportBuilder: CallReportBuilderService,
                private route: ActivatedRoute,
                private router: Router) {        
    }
    
    ngOnInit() {        
        this.route.params.subscribe(params => {
            const i = (this.route.snapshot.params['index'] || 1) - 1;
            
            if (this.index != i) {
                this.index = i;
                this._setOutcome();                
            }
        });        
    }
    
    private _setOutcome() {
        const d = this.inputData();
        if (!d) return;
        const list = d.outcomes;
        
        if (list.length > this.index) {
            this._outcome = list[this.index];
            this.skip = false;
            this.skipDisabled = true;
        } else {
            this.skipDisabled = false;
            this.skip = undefined;
            this._outcome = {
                what: '',
                why: '',
                clarify: '',
            };
        }
    }

    prev() {
        console.log('[OutcomesScreen] prev', this.index);

        if (this.index > 0) {
            this._updateUrl(this.index - 1);
        } else {
            this.router.navigate(['../../overview'], {relativeTo: this.route});
        }
    }

    next(o: CallReportRecodedOutcome) {
        console.log('[OutcomesScreen] next', this.index);
        
        const list = this.inputData()!.outcomes;
        if (list.indexOf(o) === -1) {
            list.push(o);
        }
        
        this._updateUrl(this.index + 1);
    }

    skipStep() {
        this.reportBuilder.saveInputData(3);
        this.router.navigate(['../../notes', 1], {relativeTo: this.route});
    }

    private _updateUrl(index: number) {
        console.log('[OutcomesScreen] update url', this.index);
        this.reportBuilder.saveInputData();
        this.router.navigate(['..', index + 1], {relativeTo: this.route});
    }
}
